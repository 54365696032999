import { cannotReconnectAction, connectionSlice, InitConnectionAction, removeRoomAction, SignalRAction } from "./connectionSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { END, EventChannel } from "redux-saga";
import { call, cancelled, fork, put, select, take } from "redux-saga/effects";
import { Settings } from "../../models/Settings";
import { gameSlice, startVoting, showVotes, updateActivityAction, updateRoomStatAction, pokeAction } from "../game/gameSlice";
import { store } from "../store";
import { IncomingEvents, SignalActions, SignalIncomingPayload, SignalRMiddleware, SignalRMiddlewareProvider } from "./SignalRMiddleware";
import { RootStore } from "../rootReducer";
import { ConnectionStateType } from "../../enums/ConnectionStateType";
import { logoutAction } from "../rootSaga";
import { setSettingsAction, validateSettingsAction } from "../settings/settingsSlice";

export function* pokerSignalRSaga(): Generator {
    try {
        do {
            var state = (yield take(connectionSlice.actions.setConnectionState)) as PayloadAction<ConnectionStateType>;
        } while (state.payload !== ConnectionStateType.Connecting);

        let signalRMiddleware = (yield call(SignalRMiddlewareProvider.getMiddleware, store.dispatch)) as SignalRMiddleware;

        var eventChannel = (yield call([signalRMiddleware, signalRMiddleware.connect])) as EventChannel<SignalIncomingPayload | Error>;

        yield fork(watchIncomingEvents, eventChannel);
        yield fork(watchSignalRCalls);

        let currentState = (yield call(store.getState)) as RootStore;

        if (currentState.login.desiredRoomId === "") {
            yield put(connectionSlice.actions.callSignalR({
                action: SignalActions.CreateRoom,
                args: [currentState.login.name, currentState.login.role]
            }));
        } else {
            yield put(connectionSlice.actions.callSignalR({
                action: SignalActions.JoinRoom,
                args: [currentState.login.name, currentState.login.desiredRoomId, currentState.login.role]
            }));
        }
        yield fork(watchReconnection);
        yield fork(watchCannotReconnect);
        yield take(logoutAction);
    } finally {
        if (yield cancelled()) {
            eventChannel.close();
        }
    }
}

export function* watchCannotReconnect() {
    yield take(cannotReconnectAction);
    yield put(connectionSlice.actions.setIsAbandoned(true));
    let signalRMiddleware = (yield call(SignalRMiddlewareProvider.getMiddleware, store.dispatch)) as SignalRMiddleware;
    yield call([signalRMiddleware, signalRMiddleware.close]);
}

export function* watchReconnection() {
    do {
        yield take(connectionSlice.actions.reconnected);
        let currentState = (yield select()) as RootStore;
        yield put(connectionSlice.actions.callSignalR({
            action: SignalActions.UpdateUserId,
            args: [currentState.connection.roomId, currentState.connection.oldConnectionId]
        }));
    } while (true);
}

export function* watchSignalRCalls() {
    while (true) {
        const action = (yield take(connectionSlice.actions.callSignalR)) as PayloadAction<SignalRAction>;
        let signalRMiddleware = (yield call(SignalRMiddlewareProvider.getMiddleware, store.dispatch)) as SignalRMiddleware;
        yield call([signalRMiddleware, signalRMiddleware.invoke], action.payload.action, action.payload.args);
    }

}

export function* watchIncomingEvents(eventChannel: EventChannel<SignalIncomingPayload | Error>): Generator {
    while (true) {
        try {
            // An error from socketChannel will cause the saga jump to the catch block
            const message = (yield take(eventChannel)) as SignalIncomingPayload | Error | END;

            if (message === END) {
                return;
            }

            if (Object.keys(message).includes("signalName")) {
                yield fork(processPayload, message as SignalIncomingPayload);
            } else {
                yield call(processError, message as Error);
            }
        } catch (err) {
            console.error('socket error:', err);
            // yield call(processError, err);
            // socketChannel is still open in catch block
            // if we want end the socketChannel, we need close it explicitly
            // socketChannel.close()
        }
    }
}

export function* processPayload(signalEvent: SignalIncomingPayload) {

    switch (signalEvent.signalName) {
        case IncomingEvents.AddPlayer:
            yield put(gameSlice.actions.addPlayer(signalEvent.payload.message));
            break;

        case IncomingEvents.SetPlayers:
            yield put(gameSlice.actions.setPlayers(signalEvent.payload.message));
            break;

        case IncomingEvents.SetMyself:
            yield put(gameSlice.actions.setMyself(signalEvent.payload.message));
            break;

        case IncomingEvents.ReplaceUser:
            yield put(gameSlice.actions.replacePlayer(signalEvent.payload.message));
            break;

        case IncomingEvents.SetSettings:
            yield put(setSettingsAction(signalEvent.payload.message as Settings));
            break;

        case IncomingEvents.InitConnection:
            yield put(connectionSlice.actions.initConnection(signalEvent.payload.message as InitConnectionAction));
            break;

        case IncomingEvents.DeleteUser:
            yield put(gameSlice.actions.deleteUser(signalEvent.payload.message));
            break;


        case IncomingEvents.ShowVotes:
            yield put(showVotes(signalEvent.payload));
            break;

        case IncomingEvents.StartVoting:
            yield put(startVoting(signalEvent.payload));
            break;

        case IncomingEvents.DoVoting:
            yield put(gameSlice.actions.doVoting(signalEvent.payload));
            break;

        case IncomingEvents.UpdateActivity:
            yield put(updateActivityAction(signalEvent.payload));
            break;

        case IncomingEvents.RemoveRoom:
            yield put(removeRoomAction());
            break;

        case IncomingEvents.ValidateSettings:
            yield put(validateSettingsAction(signalEvent.payload.message));
            break;

        case IncomingEvents.CannotReconnect:
            yield put(cannotReconnectAction());
            break;

        case IncomingEvents.UpdateRoomStat:
            yield put(updateRoomStatAction(signalEvent.payload.message));
            break;

        case IncomingEvents.DoPoke:
            yield put(pokeAction(signalEvent.payload));
            break;
    }
}

export function processError(error: Error) {
    connectionSlice.actions.setError(error);
}

