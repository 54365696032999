import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectionStateType } from "../../enums/ConnectionStateType";
import { SignalActions } from "./SignalRMiddleware";


export const connectionInitialState = {
    state: ConnectionStateType.Disconnected,
    isAbandoned: false,
    roomId: "",
    connectionId: "",
    oldConnectionId: "",
    error: null as Error,
    signalRAction: {
        action: null as SignalActions,
        args: [] as unknown[] | unknown
    }
}


export type ConnectionState = typeof connectionInitialState;

export type InitConnectionAction = { roomId: string, userId: string };

export type SignalRAction = { action: SignalActions, args: unknown[] | unknown };

export const removeRoomAction = createAction("removeRoomAction");
export const cannotReconnectAction = createAction("cannotReconnectAction");


export const connectionSlice = createSlice({
    name: 'connection',
    initialState: connectionInitialState,
    reducers: {
        setConnectionState: (state: ConnectionState, action: PayloadAction<ConnectionStateType>) => {
            state.state = action.payload;
        },
        initConnection: (state: ConnectionState, action: PayloadAction<InitConnectionAction>) => {
            state.roomId = action.payload.roomId;
        },
        reconnected: (state: ConnectionState, action: PayloadAction<string>) => {
            state.oldConnectionId = state.connectionId;
            state.connectionId = action.payload;
        },
        setConnectionId: (state: ConnectionState, action: PayloadAction<string>) => {
            state.oldConnectionId = state.connectionId;
            state.connectionId = action.payload;
        },
        setError: (state: ConnectionState, error: PayloadAction<Error>) => {
            state.error = error.payload;
        },
        callSignalR: (state: ConnectionState, action: PayloadAction<SignalRAction>) => {
            state.signalRAction = action.payload;
        },
        setIsAbandoned: (state: ConnectionState, action: PayloadAction<boolean>) => {
            state.isAbandoned = action.payload;
        }
    }
})

export default connectionSlice.reducer;