import React, { useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Transition } from 'react-transition-group';
import { myVotingPerformed } from '../../app/game/gameSlice';
import { RootStore } from '../../app/rootReducer';
import { AppDispatch } from '../../app/store';
import { RolesEnum } from '../../enums/RolesEnum';
import { VoteType } from '../../enums/VoteType';
import { IdontKnow } from '../../models/consts';
import { VoteScaleItem } from '../../models/VoteScaleItem';
import "./style.scss";


const ControlPanel: React.FC<ControlPanelPropsType> = (props) => {

    const [isIn, setIsIn] = React.useState(false);

    useMemo(() => {
        setTimeout(() => {
            setIsIn(true);
        }, 0);
    }, [props.voteScale]);

    useMemo(() => {
        setIsIn(false);
    }, [props.voteScale]);

    const points = useMemo(() => {
        if (props.me == null) {
            return "";
        }

        const num = props.voteScale.scale.length;
        let i = 0;
        const radius = 600;
        const degrees = 45 * Math.PI / 180;
        const step = degrees / (num - 1);

        const start = - degrees / 2;

        return props.voteScale.scale.map((point) => {
            const angle = start + step * i;

            const y = radius - radius * Math.cos(angle);
            const x = radius * Math.sin(angle);

            i++;
            var className = point.value === props.me.vote?.value ? "poker-card active" : "poker-card";
            var idk = point.voteType === VoteType.NA;
            className = idk ? className + " dont-know" : className;
            const divStyle = {
                transform: `translate( ${Math.round(y)}px, ${Math.round(x)}px)`,
                opacity: 1
            };

            return (
                <Transition in={isIn} timeout={10} key={point.value}>
                    {state => (
                        <div className="card-row" style={state === "entered" ? divStyle : {}} >
                            <button className={className} onClick={() => { props.doVoting(point, props.me.id) }}>
                                {idk === true ? IdontKnow : point.value}
                                {idk === true ? <div className="character dont-know in-panel" /> : null}
                            </button>
                        </div>
                    )}
                </Transition>
            );
        });
    }, [props, isIn]);

    const isPlayer = props.me?.role === RolesEnum.Player;

    return isPlayer && <div className="control container-control-panel text-center">
        <div className="cards rainbow">
            {points}
        </div>
    </div>;
}

const mapStateToProps = (state: RootStore) => {
    return {
        state: state.game.tableState,
        voteScale: state.settings.settings.activeVoteScale,
        me: state.game.players.find(x => x.isMe)
    }
}
const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        doVoting: (vote: VoteScaleItem, playerId: string) => dispatch(myVotingPerformed({
            message: vote
        }))
    }
}
const connector = connect(mapStateToProps, mapDispatchToProps)

export type ControlPanelPropsType = ConnectedProps<typeof connector>;

export default connector(ControlPanel);