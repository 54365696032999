import { createAction, createSlice } from "@reduxjs/toolkit";
import { type } from "os";
import { Settings, VoteScales } from "../../models/Settings";

export const settingsInitialState = {
    settings: {} as Settings,
    isVisible: false,
    saveInProgress: false,
    validationErrors: {} as { [key: string]: string },
};

export type SettingsUpdatePayload = {
    selectedVoteScaleId?: string;
    activeVoteScale: VoteScales
}

export const settingsVisibilityAction = createAction<boolean>("settingsVisibility");
export const setSettingsAction = createAction<Settings>("setSettings");
export const validateSettingsAction = createAction<string>("validateSettings");
export const updateSettingsAction = createAction<SettingsUpdatePayload>("updateSettings");

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: settingsInitialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(settingsVisibilityAction, (state, action) => {
            state.isVisible = action.payload;
        });
        builder.addCase(setSettingsAction, (state, action) => {
            state.settings = action.payload;
            state.validationErrors = {};
            state.isVisible = false;
        });
        builder.addCase(validateSettingsAction, (state, action) => {
            state.validationErrors["custom"] = action.payload;
        });
    }
});

export default settingsSlice.reducer;