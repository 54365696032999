import * as signalR from "@microsoft/signalr";
import { AppDispatch, store } from "../store";
import { connectionSlice } from "./connectionSlice";
import { END, EventChannel, eventChannel } from "redux-saga";
import { ConnectionStateType } from "../../enums/ConnectionStateType";
import { AsyncPayloadAction } from "../AsyncPayloadAction";

export class SignalIncomingPayload {

    public signalName: IncomingEvents;
    public payload: AsyncPayloadAction<any>;
}




export enum IncomingEvents {
    SetSettings = "SetSettings",
    InitConnection = "InitConnection",
    SetPlayers = "SetPlayers",
    ReplaceUser = "ReplaceUser",
    SetMyself = "SetMyself",
    AddPlayer = "AddPlayer",
    DeleteUser = "DeleteUser",
    ShowVotes = "ShowVotes",
    StartVoting = "StartVoting",
    DoVoting = "DoVoting",
    UpdateActivity = "UpdateActivity",
    RemoveRoom = "RemoveRoom",
    ValidateSettings = "ValidateSettings",
    CannotReconnect = "CannotReconnect",
    UpdateRoomStat = "UpdateRoomStat",
    DoPoke = "DoPoke"
}




export enum SignalActions {
    JoinRoom,
    CreateRoom,
    UpdateUserId,
    DoVoting,
    ShowVotes,
    StartVoting,
    UpdateActivity,
    UpdateSettings,
    DoPoke
}

export type IncomingEventsStrings = keyof typeof IncomingEvents;
let middleware: SignalRMiddleware;
export const SignalRMiddlewareProvider = {
    getMiddleware: (dispatchFn: AppDispatch) => {
        if (middleware == null) {
            middleware = new SignalRMiddleware(dispatchFn);
        }
        return middleware;
    }
}

export class SignalRMiddleware {
    private hubConnection: signalR.HubConnection;

    constructor(private dispatchFn: AppDispatch) {
    }

    public async connect(): Promise<EventChannel<SignalIncomingPayload | Error>> {

        this.hubConnection = new signalR
            .HubConnectionBuilder()
            .withUrl("/gameHub")
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Debug)
            .build();


        await this.hubConnection
            .start()
            .catch((err: any) => {
                console.log('Error while establishing connection :(')
            });
        (window as any)["signalR"] = this.hubConnection;
        this.dispatchFn(connectionSlice.actions.setConnectionState(ConnectionStateType.Connected));

        this.dispatchFn(connectionSlice.actions.setConnectionId(this.hubConnection.connectionId));


        this.hubConnection.onclose(x => this.dispatchFn(connectionSlice.actions.setConnectionState(ConnectionStateType.Disconnected)));
        this.hubConnection.onreconnecting(x => this.dispatchFn(connectionSlice.actions.setConnectionState(ConnectionStateType.RetryingToConnect)));
        this.hubConnection.onreconnected(connectionId => {
            this.dispatchFn(connectionSlice.actions.reconnected(connectionId));
            this.dispatchFn(connectionSlice.actions.setConnectionState(ConnectionStateType.Connected));
        });

        return eventChannel<SignalIncomingPayload | Error>(emit => {

            this.hubConnection.onclose(x => emit(END));

            let handlers: { [key: string]: (...args: any[]) => void } = {};

            const getEventHandler = (signalName: IncomingEvents) => {
                return (message: { payload: any }) => {
                    console.log(signalName);

                    let payload = null;
                    if (message != null && message.payload != null) {
                        payload = message.payload;
                    }

                    emit({
                        signalName: signalName,
                        payload: {
                            isIncoming: true,
                            message: payload
                        } as AsyncPayloadAction
                    });
                }
            };

            for (const key in IncomingEvents) {
                if (!isNaN(key as any)) {
                    continue;
                }
                if (Object.prototype.hasOwnProperty.call(IncomingEvents, key)) {
                    let handler = getEventHandler(key as unknown as IncomingEvents);
                    handlers[key] = handler;
                    this.hubConnection.on(key, handler);
                }
            }

            // the subscriber must return an unsubscribe function
            // this will be invoked when the saga calls `channel.close` method
            const unsubscribe = async () => {
                for (const key in handlers) {
                    if (Object.prototype.hasOwnProperty.call(handlers, key)) {
                        this.hubConnection.off(key, handlers[key]);
                    }
                }
                await this.hubConnection.stop();
            }

            return unsubscribe;
        });
    }

    public async close() {
        await this.hubConnection.stop();
    }

    public async invoke<T>(methodName: SignalActions, args: any[] | any): Promise<T | Error> {
        try {
            return await this.hubConnection.invoke<T>(SignalActions[methodName].toString(), ...args);
        } catch (e: any) {
            console.error(e);
            return new Error(e);
        }
    }
}