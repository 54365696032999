import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import QRCode from 'qrcode';
import "../../../icons.scss";
import "./ShareRoomButton.scss";

type ShareRoomButtonPropsType = {
  roomLink: string
}

export const ShareRoomButton: React.FC<ShareRoomButtonPropsType> = ({ roomLink }: ShareRoomButtonPropsType) => {

  const [copied, setCopied] = React.useState(false);

  const copyLink = () => {
    var copyText = document.getElementById("link-text-input") as any;
    if (copyText == null) {
      return;
    }
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    copyText.blur();
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 4000);
  };

  const drawQRCode = () => {
    var canvas = document.getElementById('share-qr-code-canvas');
    QRCode.toCanvas(canvas, roomLink, {
      width: 178,
      scale: 1,
      color: {
        light: "#E6EFFE"
      }
    }, (error) => {
    });
  };

  return <OverlayTrigger
    trigger="click"
    key={"left-start"}
    placement={"bottom-end"}
    transition={true}
    overlay={
      <Popover id="share-room-popup-container" className={`share-room-popup-container`}
        placement="bottom">
        <Popover.Body>
          <div className="share-qr-code-container" >
            <canvas id="share-qr-code-canvas" style={{ width: 180, height: 180 }}></canvas>
          </div>
          <div className="share-link-container">
            <h1>Share this room</h1>
            <div className="link-row">
              <div className="share-link">
                <div className="link-text">
                  <input id="link-text-input" type="text" onFocus={(event) => event.target.select()} readOnly={true} defaultValue={roomLink}></input>
                </div>
              </div>
              <div>
                <div className="copy-paste" onClick={copyLink}>
                </div>
                <div className="success-label" style={{ opacity: copied ? 1 : 0 }}>Copied</div>
              </div>
            </div>
            {/* <div>Reserve unique link <a href="#">here</a></div> */}
          </div>
        </Popover.Body>
      </Popover >
    }
    onEntered={drawQRCode}>
    <div className="button-container share"><div className="share-icon" />Share Room</div>
  </OverlayTrigger>
}