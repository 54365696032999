import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootStore } from '../app/rootReducer';
import GameTable from './Table/GameTable';
import PlayersRow from './Player/PlayersRow';
import { AppDispatch } from '../app/store';
import { ActivityThreshold, updateActivityAction } from '../app/game/gameSlice';


const Main: React.FC<MainPropsType> = (props) => {
    useEffect(() => {
        let lastMouseMove = Date.now();
        function watchMouseMove() {

            const diff = ActivityThreshold;
            const d = Date.now() - lastMouseMove;
            if (d > diff) {
                lastMouseMove = (Date.now() + diff);
                props.updateActivity();
            }
        }
        document.body.addEventListener('mousemove', watchMouseMove);
        return function cleanup() {
            window.removeEventListener('mousemove', watchMouseMove);
        }
    });

    return <main className="container text-center">
        <GameTable />
        <PlayersRow />
    </main>;

}

const mapState = (store: RootStore) => {
    const me = store.game.players.find(x => x.isMe);
    return {
        me: me,
        players: store.game.players,
        tableState: store.game.tableState
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        updateActivity: () => dispatch(updateActivityAction())
    };
};

const connector = connect(mapState, mapDispatchToProps);

export type MainPropsType = ConnectedProps<typeof connector>;

export default connector(Main);