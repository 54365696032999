import React from 'react';
import { RootStore } from '../../app/rootReducer';
import withRoomCheckContainer from '../../app/withRoomCheckContainer';
import { connect } from 'react-redux';
import "./GameContainer.scss";
import { User } from '../../models/User';
import { TableState } from '../../enums/TableState';
import { RoomUpdateInfo, UpdateActionType } from '../../models/RoomUpdateInfo';

export type GameStatPropsType = {
    state: RoomUpdateInfo
}


const GameStat: React.FC<GameStatPropsType> = (props: GameStatPropsType) => {

    const [isMessageHidden, setIsMessageHidden] = React.useState(true);

    let actionText = "";
    switch (props.state.actionType) {
        case UpdateActionType.GameStarted:
            actionText = " started new round";
            break;
        case UpdateActionType.TableOpened:
            actionText = " opened cards";
            break;
        case UpdateActionType.PokedAnother:
            actionText = " poked ";
            break;
    }
    let timeout = React.useRef(null);

    React.useEffect(() => {
        setIsMessageHidden(false);
        if (timeout.current != null) {
            clearInterval(timeout.current);
        }
        timeout.current = setTimeout(() => {
            setIsMessageHidden(true);
        }, 10000);

    }, [props]);

    return <div className="game-stat">
        <div>
            <GameStatStopwatch timestamp={props.state.timestamp} />
            {props.state.actorPlayer && <div className={`actor ${isMessageHidden ? "out" : "in"}`}>
                <span className="name">{props.state.actorPlayer.name}</span>
                <span>{actionText}</span>
                {props.state.targetPlayer && <span className="name">{props.state.targetPlayer.name}</span>}
            </div>}
        </div>
    </div>;
}

const GameStatStopwatch: React.FC<{ timestamp: number }> = (props) => {
    const [timeElapsed, setTimeElapsed] = React.useState(0);
    let interval = React.useRef(null);

    React.useEffect(() => {
        if (interval.current != null) {
            clearInterval(interval.current);
            const now = new Date().getTime();
            setTimeElapsed(now - props.timestamp);
        }
        interval.current = setInterval(() => {
            const now = new Date().getTime();
            setTimeElapsed(now - props.timestamp);
        }, 1000);

    }, [props]);

    const minutes = Math.floor(timeElapsed / 60000);
    const seconds = Math.floor((timeElapsed % 60000) / 1000);

    return <span className="time">
        {minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}
    </span>;
}

const mapStateToProps = (state: RootStore): GameStatPropsType => {
    if (state.game.roomStat == null) {
        return {
            state: {
                actionType: UpdateActionType.GameStarted,
                actorPlayer: null,
                targetPlayer: null,
            }
        }
    }
    return {
        state: state.game.roomStat
    };
}

const container = connect(mapStateToProps)(GameStat);

export default container;
