import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createRootReducer } from './rootReducer';
import { createBrowserHistory } from 'history';


const devMode = process.env.NODE_ENV === 'development';

export const pokerHistory = createBrowserHistory();

// create the saga middleware
export const sagaMiddleware = createSagaMiddleware();
// create root reducer
const reducer = createRootReducer(pokerHistory);

export const store = configureStore({
    reducer,
    devTools: devMode,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware, routerMiddleware(pokerHistory)),
});

export type AppDispatch = typeof store.dispatch;



