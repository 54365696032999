import React from "react";
import { RolesEnum } from "../../enums/RolesEnum";
import { AllRightsReserved } from "../Footer/AllRightsReserved";
import 'react-dropdown/style.css';
import "../../icons.scss";
import "./Login.scss";
import Dropdown from 'react-dropdown';
import { PoweredBy7pace } from "../Footer/PoweredBy7pace";
import { connect, ConnectedProps } from "react-redux";
import { RootStore } from "../../app/rootReducer";
import { doLoginAction, loginSlice } from "./loginSlice";
import { useParams } from "react-router";
import { Alert } from "react-bootstrap";


const Login: React.FC<PropsFromRedux> = (props: PropsFromRedux) => {
    const [selectedName, setSelectedName] = React.useState(props.name ?? "");
    const [selectedRole, setSelectedRole] = React.useState(props.role ?? RolesEnum.Player);
    const [shaky, setShaky] = React.useState(false);
    const [validationError, setValidationError] = React.useState(false);
    const mounted = React.useRef(false);
    const { roomId } = useParams<{ roomId: string }>();

    React.useEffect(() => {
        mounted.current = false;
    }, []);

    React.useEffect(() => {
        var obj = { username: selectedName, role: selectedRole };
        localStorage.setItem('userProps', JSON.stringify(obj));
        if ((selectedName ?? "").trim() != '') {
            setValidationError(false);
        }
    }, [selectedName, selectedRole]);

    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            return;
        }
        setShaky(true);
        setTimeout(() => {
            setShaky(false);
        }, 500);
    }, [selectedRole]);

    const validateAndLogin = () => {
        if (!(selectedName ?? "").trim()) {
            setValidationError(true);
            return;
        }
        setValidationError(false);

        if (roomId) {
            props.setDesiredRoomId(roomId);
        }

        props.doLogin({ name: selectedName, role: selectedRole });
    }


    return <div className="login-page-wrapper">
        <div className="login-page-container-outer">
            <div className="login-page-container-inner">
                <div className="login-page-body-container">
                    <div className="login-page-left-container">
                        <div className="login-image">
                            <div className="logo-poker" />
                        </div>
                    </div>
                    <div className="login-page-right-container">
                        {props.isRoomRemoved && <Alert variant="warning">
                            Your room was closed as it was inactive for a long time.
                            <br />
                            Just create a new one to continue estimating.
                        </Alert>}
                        <div className="login-page-right-header">
                            {!props.name && <h1 className="login-page-header-text">Enter room</h1>}
                            {props.name && <h1 className="login-page-header-text">Welcome back, {props.name}</h1>}
                        </div>
                        <div className="login-page-right-body">
                            <div className="login-page-row">
                                <label>Your Name</label>
                                <input type="text" id="login-name" onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        validateAndLogin();
                                    }
                                }} defaultValue={selectedName} placeholder="Name" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedName(event.target.value)} ></input>
                                {validationError && <span className="validation-error" /*style={{ opacity: validationError ? 1 : 0 }}*/>Please enter your name</span>}
                            </div>
                            <div className="login-page-row">
                                <label>Role</label>
                                <Dropdown controlClassName={"selected-role-" + selectedRole} options={[{ value: RolesEnum.Player, label: 'Player' },
                                { value: RolesEnum.Observer, label: 'Observer' }]} placeholder={selectedRole}
                                    value={selectedRole}
                                    onChange={(option) => setSelectedRole(option.value as RolesEnum)}
                                />
                                <div className={`player-image ${shaky ? 'shaky' : ''}`}>
                                    <div className={`character ${selectedRole}`} />
                                </div>
                            </div>
                            <div className="login-page-row last">
                                <button className="login-page-enter-button" id="login-button" type="button" onClick={() => validateAndLogin()}>Enter</button>
                            </div>
                        </div>
                        <PoweredBy7pace />
                    </div>
                </div>

                <AllRightsReserved />
            </div>
        </div>
    </div>
}

const mapState = (store: RootStore) => store.login;

const mapDispatch = {
    doLogin: doLoginAction,
    setDesiredRoomId: loginSlice.actions.setDesiredRoomId
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export const LoginContol = connector(Login);