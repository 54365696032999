import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlayerState } from "../../enums/PlayerState";
import { RolesEnum } from "../../enums/RolesEnum";
import { TableState } from "../../enums/TableState";
import { RoomUpdateInfo } from "../../models/RoomUpdateInfo";
import { Settings } from "../../models/Settings";
import { getPlayerStatus, User } from "../../models/User";
import { VotedPlayer, VoteScaleItem } from "../../models/VoteScaleItem";
import { VotesState } from "../../models/VotesState";
import { AsyncPayloadAction } from "../AsyncPayloadAction";


export const gameInitialState = {
    players: [] as User[],
    observers: [] as User[],
    tableState: TableState.Voting,
    votesState: null as VotesState,
    roomStat: null as RoomUpdateInfo,
    isBeingPoked: false
}

export const ActivityThreshold = 20000;

export type GameState = typeof gameInitialState;
export type ReplaceUserAction = { oldId: string, player: User };

export const showVotes = createAction<AsyncPayloadAction<VotesState>>("showVotes");
export const startVoting = createAction<AsyncPayloadAction>("startVoting");
export const myVotingPerformed = createAction<AsyncPayloadAction<VoteScaleItem>>("myVotingPerformed");
export const updateActivityAction = createAction<AsyncPayloadAction | AsyncPayloadAction<string>>("updateActivity");
export const setPlayerIdleAction = createAction<User>("setPlayerIdleAction");
export const updateRoomStatAction = createAction<RoomUpdateInfo>("updateRoomStatAction");
export const pokeAction = createAction<AsyncPayloadAction<string>>("pokeAction");
export const isBeingPokedAction = createAction<boolean>("isBeingPokedAction");


export const gameSlice = createSlice({
    name: 'game',
    initialState: gameInitialState,
    reducers: {
        addPlayer: (state: GameState, action: PayloadAction<User>) => {
            if (action.payload.role === RolesEnum.Observer) {
                state.observers.push(action.payload);
            } else {
                state.players.push(action.payload);
            }
        },
        setMyself: (state: GameState, action: PayloadAction<User>) => {

            let arr;
            if (action.payload.role === RolesEnum.Observer) {
                arr = state.observers;
            } else {
                arr = state.players;
            }
            const myIndex = arr.findIndex(p => p.id === action.payload.id);

            let first = arr[0];
            arr[0] = arr[myIndex];
            arr[0].isMe = true;
            arr[myIndex] = first;
        },
        setPlayers: (state: GameState, action: PayloadAction<User[]>) => {
            const players = action.payload.filter(x => x.role === RolesEnum.Player);
            const observers = action.payload.filter(x => x.role === RolesEnum.Observer);

            state.players = players;
            state.observers = observers;
        },
        deleteUser: (state: GameState, action: PayloadAction<User>) => {
            let arr;
            if (action.payload.role === RolesEnum.Observer) {
                arr = state.observers;
            } else {
                arr = state.players;
            }
            const ind = arr.findIndex(x => x.id === action.payload.id);
            if (ind > -1) {
                arr.splice(ind, 1);
            }
        },
        doVoting: (state: GameState, action: PayloadAction<AsyncPayloadAction<VotedPlayer>>) => {
            const player = state.players.find(x => x.id === action.payload.message.id);
            if (player == null) {
                console.warn("doVoting cannot find player with id: " + action.payload.message.id);
                return;
            }
            player.vote = action.payload.message.vote;
            player.status = PlayerState.Voted;
        },
        resetAllVotes: (state: GameState) => {
            state.players.forEach(x => {
                x.isFirstVoted = x.isLastVoted = false;
                x.pointsHistory = [];
                x.vote = null;
                x.status = getPlayerStatus(x);
                x.isPoked = false;
            });
            state.votesState = null;
        },
        replacePlayer: (state: GameState, action: PayloadAction<ReplaceUserAction>) => {

            const newPlayer = action.payload.player;

            var array = newPlayer.role === RolesEnum.Observer ? state.observers : state.players;
            //const player = array.find(x => x.id === action.payload.oldId);
            array.push(newPlayer);

        }
    },
    extraReducers: (b) => {
        b
            .addCase(showVotes, (state, action: PayloadAction<AsyncPayloadAction<VotesState>>) => {
                state.tableState = TableState.Opened;

                if (action.payload.isIncoming) {
                    state.votesState = action.payload.message;
                }
            })
            .addCase(startVoting, (state, action) => {
                state.tableState = TableState.Voting;
            })
            .addCase(updateActivityAction, (state, action: PayloadAction<AsyncPayloadAction | AsyncPayloadAction<string>>) => {
                if (action.payload?.isIncoming === true) {
                    const player = state.players.find(x => x.id === action.payload.message);
                    if (player == null) {
                        console.warn("updateActivity cannot find player with id: " + action.payload.message);
                        return;
                    }
                    player.lastActivity = Date.now();
                    if (player.vote == null) {
                        player.status = PlayerState.Thinking;
                    }
                }
            })
            .addCase(setPlayerIdleAction, (state, action: PayloadAction<User>) => {
                const player = state.players.find(x => x.id === action.payload.id);
                if (player == null) {
                    console.warn("setPlayerIdle cannot find player with id: " + action.payload.id);
                    return;
                }
                player.status = PlayerState.Idle;
            })
            .addCase(updateRoomStatAction, (state, action: PayloadAction<RoomUpdateInfo>) => {
                state.roomStat = action.payload;
            })
            .addCase(isBeingPokedAction, (state, action: PayloadAction<boolean>) => {
                state.isBeingPoked = action.payload;
            })
            .addCase(pokeAction, (state, action: PayloadAction<AsyncPayloadAction<string>>) => {
                const player = state.players.find(x => x.id === action.payload.message);
                player.isPoked = true;
            });
    }
})

export default gameSlice.reducer;