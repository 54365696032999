import React, { useEffect, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { RootStore } from "../../app/rootReducer";
import { ConnectionStateType } from "../../enums/ConnectionStateType";
import "./ReconnectingIndicator.scss";

type IndicatorPropsType = {
    isReconnecting: boolean,
    isAbandoned: boolean,
}

const Indicator: React.FC<IndicatorPropsType> = (props) => {
    const [isShown, setIsShown] = useState(false);
    const [isHiding, setIsHiding] = useState(false);

    useEffect(() => {
        if (props.isReconnecting || props.isAbandoned) {
            setIsShown(props.isReconnecting || props.isAbandoned);
            return;
        }
        setIsHiding(true);
        setTimeout(() => {
            setIsShown(false);
            setIsHiding(false);
        }, 500);
    }, [props]);

    if (!isShown) {
        return null;
    }
    return <div className={`reconnecting-indicator-cover ${isHiding ? 'hiding' : ''} ${props.isAbandoned ? 'disconnected' : ''}`}>
        <div className="reconnecting-indicator position-fixed start-50 translate-middle-x" style={{ "zIndex": 11 }}>
            <Alert variant="warning">
                {props.isReconnecting && <> <Spinner size="sm" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner> <span>Trying to reconnect</span></>}
                {props.isAbandoned && <span>Failed to reconnect. Please try to refresh page and re-join the room.</span>}
            </Alert>
        </div>
    </div>;
}

const mapStateToProps = (state: RootStore): IndicatorPropsType => {
    return {
        isReconnecting: state.connection.state === ConnectionStateType.RetryingToConnect
            || state.connection.state === ConnectionStateType.Connecting
            || (state.connection.state === ConnectionStateType.Disconnected && !state.connection.isAbandoned),
        isAbandoned: state.connection.isAbandoned
    };
}

export default connect(mapStateToProps)(Indicator);
