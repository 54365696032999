import React, { useMemo } from 'react';
import { VoteType } from '../../enums/VoteType';
import { VoteScales } from '../../models/Settings';
import { VoteScaleItem } from '../../models/VoteScaleItem';
import VoteScaleItemView from './VoteScaleItem';

export type CustomScaleProps = {
    isSelected: boolean;
    scale: VoteScales;
    update: (scale: VoteScales) => void;
}

const CustomScale: React.FC<CustomScaleProps> = (props) => {

    const [scaleItems, setScaleItems] = React.useState<VoteScaleItem[]>(props.scale.scale);

    const updateScaleItems = (scaleItems: VoteScaleItem[]) => {
        setScaleItems(scaleItems);
        props.update({
            id: props.scale.id,
            scale: scaleItems,
        });
    }

    return (
        <div className="custom-scale-set">
            <div className="custom-scale-set-header">Custom</div>
            <div className="break"></div>
            <div className="scale-set custom">
                {scaleItems.map((item, index) => {
                    return (
                        item.voteType === VoteType.Normal ? <div key={index}>
                            <input disabled={!props.isSelected} className="scale-input" maxLength={5} type="text" defaultValue={item.value} onChange={(e) => {
                                e.persist();
                                const cloned = [...scaleItems];
                                const newItem = { ...cloned[index] };
                                newItem.value = e.target.value;
                                cloned[index] = newItem;
                                updateScaleItems(cloned);
                            }} />
                        </div> : <div key={index}><VoteScaleItemView item={item} /></div>
                    )
                })}
                <div>
                    {scaleItems.length > 0 && <button disabled={!props.isSelected} className="button success add-scale-item" onClick={() => {
                        // call updateScaleItems with removed last item
                        updateScaleItems([...scaleItems.slice(0, scaleItems.length - 1)]);
                    }}>-</button>}
                </div>
                <div>
                    {scaleItems.length < 10 && <button disabled={!props.isSelected} className="button success add-scale-item" onClick={() => {
                        if (scaleItems.length < 10) {
                            updateScaleItems([...scaleItems, { value: '', voteType: VoteType.Normal }])
                        }
                    }}>+</button>}
                </div>
                <div>
                    {scaleItems.length < 10 && scaleItems.filter(x => x.voteType === VoteType.NA)
                        .length == 0 && <button disabled={!props.isSelected} className="button success add-scale-item" onClick={() => {
                            if (scaleItems.length < 10) {
                                updateScaleItems([...scaleItems, { value: '', voteType: VoteType.NA }])
                            }
                        }}>+ I don't know</button>}
                </div>
            </div>
        </div>
    );
}

export default CustomScale;