import { User } from "./User";

export type RoomUpdateInfo = {
    actionType: UpdateActionType,
    timestamp?: number,
    actorPlayer: User,
    targetPlayer: User
}

export enum UpdateActionType {
    GameStarted = "GameStarted",
    TableOpened = "TableOpened",
    PokedAnother = "PokedAnother",
}