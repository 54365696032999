import React from "react";

import "./Footer.scss";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const AllRightsReserved = () => {
    return <div className="login-page-footer">
        Made in Germany by &nbsp;
        <OverlayTrigger
            trigger="click"
            key={"left-start"}
            placement={"top"}
            transition={true}
            rootClose={true}
            overlay={
                <Popover id="about-us" className={` `}
                    placement="bottom">
                    <Popover.Body>
                        <div>
                            Contributors <br />
                            <a href="https://github.com/mlutsan" target={"_blank"}>Maxim Lutsan</a>,
                            Dmitrii Vavel,
                            George Minko,
                            Igor Kamynin,
                            Roman Kadkalo,
                            Eugene Kolomytsev,
                            Maxim Shestakov and others
                        </div>

                    </Popover.Body>
                </Popover >
            }>
            <a href="javascript:" className="no-underline">7pace <sup>Labs</sup></a>
        </OverlayTrigger>

    </div>
}