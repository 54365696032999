import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { gameSlice } from '../../app/game/gameSlice';
import { RootStore } from '../../app/rootReducer';
import { connectionSlice } from '../../app/signalR/connectionSlice';
import { SignalActions } from '../../app/signalR/SignalRMiddleware';
import { AppDispatch } from '../../app/store';
import { TableState } from '../../enums/TableState';

import './GameButtons.scss';



const GameButtons: React.FC<ActionButtonsPropsType> = (props) => {
    return (
        <div className="game-buttons">
            <div className="flex-container">

                <div className="voted-unselected legend-item">
                    <div className="character voted-unselected voted"></div>
                    <span className="legend-label">VOTED</span>
                </div>

                <div className="idle-unselected legend-item">
                    <div className="character idle-unselected idle"></div>
                    <span className="legend-label">IDLE</span>
                </div>

                <div className="thinking-unselected legend-item">
                    <div className="character thinking-unselected thinking"></div>
                    <span className="legend-label">THINKING</span>
                </div>

                <div className="buttons-block">
                    <button className='game-button show-cards' disabled={!props.canShowCards} onClick={() => props.show(props.roomId)}>SHOW CARDS</button>
                    <button className='game-button start-new' onClick={() => props.startNew(props.roomId)}>START NEW</button>
                </div>
            </div>
        </div>);
}

const mapStateToProps = (state: RootStore) => {
    return {
        state: state.game.tableState,
        roomId: state.connection.roomId,
        canShowCards: state.game.players.some(p => p.vote != null)
    }
}
const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        show: (roomId: string) => dispatch(connectionSlice.actions.callSignalR({
            action: SignalActions.ShowVotes,
            args: [roomId]
        })),
        startNew: (roomId: string) => dispatch(connectionSlice.actions.callSignalR({
            action: SignalActions.StartVoting,
            args: [roomId]
        })),
    }
}
const connector = connect(mapStateToProps, mapDispatchToProps)

export type ActionButtonsPropsType = ConnectedProps<typeof connector>;
export default connector(GameButtons);