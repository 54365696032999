import { all, call, delay, put, select, takeEvery } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { PlayerState } from "../../enums/PlayerState";
import { User } from "../../models/User";
import { AsyncPayloadAction } from "../AsyncPayloadAction";
import { RootStore } from "../rootReducer";
import { connectionSlice, ConnectionState } from "../signalR/connectionSlice";
import { SignalActions } from "../signalR/SignalRMiddleware";
import { launchIsBeingPokedTitle as launchIsBeingPoked, stopIsBeingPokedTitle } from "./documentTitleChanger";
import { gameSlice, startVoting, showVotes, myVotingPerformed, updateActivityAction, GameState, ActivityThreshold, setPlayerIdleAction, pokeAction, isBeingPokedAction } from "./gameSlice";


const signalRSagaGetter = (signalRAction: SignalActions) => {
    return function* (action: PayloadAction<AsyncPayloadAction<any>>) {
        if (action.payload?.isIncoming === true) {
            return;
        }
        yield put(connectionSlice.actions.callSignalR({
            action: signalRAction,
            args: action.payload?.message !== undefined ? [action.payload?.message] : []
        }));
    }
}

const messageSagas = [
    takeEvery(startVoting, signalRSagaGetter(SignalActions.StartVoting)),
    takeEvery(showVotes, signalRSagaGetter(SignalActions.ShowVotes)),
    takeEvery(myVotingPerformed, signalRSagaGetter(SignalActions.DoVoting)),
    takeEvery(updateActivityAction, signalRSagaGetter(SignalActions.UpdateActivity)),
    takeEvery(pokeAction, signalRSagaGetter(SignalActions.DoPoke)),


    takeEvery(startVoting, watchStartVoting),
    takeEvery(gameSlice.actions.setPlayers, watchSetPlayers),
    takeEvery(pokeAction, watchPoke)
];


export function* gameSaga(): Generator {
    yield all(messageSagas);
    yield call(watchPlayersStatus);
}

export function* watchStartVoting() {
    yield put(gameSlice.actions.resetAllVotes());
}

export function* watchSetPlayers(action: PayloadAction<User[]>) {
    const state = (yield select((state: RootStore) => {
        return {
            connection: state.connection,
            game: state.game
        };
    })) as {
        connection: ConnectionState,
        game: GameState
    };

    var me = action.payload.find(p => p.id === state.connection.connectionId);

    yield put(gameSlice.actions.setMyself(me));
}

function* watchPlayersStatus(): Generator {

    const gameState = (yield select((state: RootStore) => state.game)) as GameState;

    for (const player of gameState.players) {
        const timeDiff = Date.now() - player.lastActivity;

        if (player.status === PlayerState.Thinking && timeDiff > ActivityThreshold) {
            yield put(setPlayerIdleAction(player));
        }
    }

    yield delay(1000);
    yield call(watchPlayersStatus);
}

function* watchPoke(payload: PayloadAction<AsyncPayloadAction<string>>) {
    if (!payload.payload.isIncoming) {
        //button clicked by current user
        return;
    }
    const connectionState = (yield select((state: RootStore) => state.connection)) as ConnectionState;
    if (connectionState.connectionId !== payload.payload.message) {
        return;
    }

    yield put(isBeingPokedAction(true));
    const interval = (yield call(launchIsBeingPoked)) as { interval: NodeJS.Timeout, originalTitle: string };

    yield delay(4000);
    yield call(stopIsBeingPokedTitle, interval.interval, interval.originalTitle);

    yield put(isBeingPokedAction(false));
}