import { PayloadAction } from "@reduxjs/toolkit";
import { routerActions } from "connected-react-router";
import { shallowEqual } from "react-redux";
import { put, select, take } from 'redux-saga/effects';
import { doLoginAction, loginSlice, LoginState } from "../components/Login/loginSlice";
import { ConnectionStateType } from "../enums/ConnectionStateType";
import { RootStore } from "./rootReducer";
import { connectionSlice, InitConnectionAction } from "./signalR/connectionSlice";

export function* fullAuthFlowCheck(): Generator {
    const savedLoginData = "savedLoginData";
    while (true) {
        const userData: LoginState = JSON.parse(localStorage.getItem(savedLoginData) ?? "{}");
        //we have data stored in local storage
        if (!shallowEqual(userData, {})) {
            yield put(loginSlice.actions.setLoginInfo(userData));
        }

        const loginData = (yield take(doLoginAction)) as PayloadAction<LoginState>;

        yield put(loginSlice.actions.setLoginInfo(loginData.payload));

        let store = (yield select()) as RootStore;

        localStorage.setItem(savedLoginData, JSON.stringify(store.login));

        yield put(connectionSlice.actions.setConnectionState(ConnectionStateType.Connecting));

        yield take(connectionSlice.actions.setConnectionState);

        yield take(connectionSlice.actions.setConnectionId);
        const connectionInfo = (yield take(connectionSlice.actions.initConnection)) as PayloadAction<InitConnectionAction>;

        //we expect that all events are sent
        yield put(routerActions.push("/room/" + connectionInfo.payload.roomId));

        yield put(loginSlice.actions.setRoomRemoved(false));
    }
}


