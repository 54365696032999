import React, { useMemo } from 'react';
import { TableState } from '../../enums/TableState';

import './GameTable.scss';
import "../../icons.scss";
import { User } from '../../models/User';
import { RootStore } from '../../app/rootReducer';
import { connect } from 'react-redux';
import { HighlightType, VotesState } from '../../models/VotesState';
import { ProgressBar } from 'react-bootstrap';
import { getVoteText } from '../Reusable/Vote';

export type TablePanelPropsType = {
    tableState: TableState,
    players: User[],
    stat: VotesState
}

const GameTable: React.FC<TablePanelPropsType> = (props) => {
    const { tableState, players, stat } = props;

    const getProgressBarText = useMemo(() => {
        const tableState = props.tableState;
        if (tableState === TableState.Voting)
            return 'Waiting for all votes ...';
        if (stat == null) {
            return null;
        }
        if (tableState === TableState.Opened) {
            switch (stat.highlightedVote?.highlightType) {
                case null:
                    return "NA";
                case HighlightType.Consensus:
                    return "Consensus!"
                case HighlightType.MostVotes:
                    return "Most votes"
                case HighlightType.Nothing:
                case HighlightType.Doubts:
                    return "";
                case HighlightType.SingleVote:
                    return "Single vote";
            }
        }
    }, [props.tableState, stat]);

    const getVotesStat = useMemo(() => {
        if (stat == null) {
            return null;
        }
        switch (stat.highlightedVote?.highlightType) {
            case null:
                return "NA";
            case HighlightType.Consensus:
            case HighlightType.SingleVote:
                return <div className="consensus">{getVoteText(stat.highlightedVote.highlightedVote.vote)}</div>
            case HighlightType.MostVotes:
                return <div className="most-votes">{getVoteText(stat.highlightedVote.highlightedVote.vote)}</div>
            case HighlightType.Doubts:
                return <div className="doubts">{getVoteText(stat.highlightedVote.highlightedVote.vote)} or {getVoteText(stat.highlightedVote.highlightedVoteSecond.vote)}</div>
            case HighlightType.Nothing:
                return <div className="nothing">No conclusion</div>
        }
    }, [stat]);


    const now = useMemo(() => {
        return props.players.filter(x => x.vote != null).length;
    }, [props.players]);


    return (<div className="poker-center-table">
        <label className='table-state-label' htmlFor="voting">{getProgressBarText}</label>
        {tableState === TableState.Opened && stat?.highlightedVote && <>
            <div className='point'>{getVotesStat}</div>
        </>}
        {tableState === TableState.Voting && <> <ProgressBar max={players.length} now={now} />
            <div className="table-amount">{`${now}/${players.length}`}</div></>}


    </div>);
}

const mapStateToProps = (state: RootStore): TablePanelPropsType => {
    return {
        tableState: state.game.tableState,
        players: state.game.players,
        stat: state.game.votesState
    };
}

export default connect(mapStateToProps)(GameTable);