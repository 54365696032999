import React, { ComponentType, FunctionComponent } from "react";
import { connect, useDispatch } from "react-redux";
import { generatePath, Redirect, RouteComponentProps, useParams } from "react-router-dom";
import { loginSlice } from "../components/Login/loginSlice";
import { ConnectionStateType } from "../enums/ConnectionStateType";
import { RootStore } from "./rootReducer";

type RoomCheckProps = {
  hasJoinedRoom: boolean,
  joiningRoomId?: string
}

type Props = RoomCheckProps & RouteComponentProps

const roomCheck = <P extends Props>(Component: React.FunctionComponent<P> | React.ComponentType<P>): (props: Props) => any => {

  return (props: Props) => {
    const dispatch = useDispatch();

    let { roomId } = useParams<{ roomId: string }>();

    if (!props.hasJoinedRoom) {
      dispatch(loginSlice.actions.setDesiredRoomId(roomId));
      const path = generatePath("/join/:roomId", {
        roomId: roomId
      });
      return <Redirect to={path}></Redirect>
    }
    return <Component {...props as P}></Component>;
  }
}


const mapState = (store: RootStore): RoomCheckProps => ({
  hasJoinedRoom: store.connection.roomId !== ""
});

const withRoomCheckContainer = (component: React.FunctionComponent<any> | React.ComponentType<any>) => {
  const connected = connect(mapState)(roomCheck(component));
  return connected;
}

export default withRoomCheckContainer;
