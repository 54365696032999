import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { pokeAction } from '../../app/game/gameSlice';
import { RootStore } from '../../app/rootReducer';
import { AppDispatch } from '../../app/store';
import { PlayerState } from '../../enums/PlayerState';
import { TableState } from '../../enums/TableState';
import { VoteType } from '../../enums/VoteType';
import { IdontKnow } from '../../models/consts';
import { VoteScaleItem } from '../../models/VoteScaleItem';
import './Player.scss'

// type PlayerPropsType = {
//     userName: string;
//     userId: string;
//     isMe: boolean;
//     points?: VoteScaleItem;
//     isFirst: boolean;
//     isLast: boolean;
//     playerStatus: PlayerState;
//     pointsHistory?: string[];
//     tableState: TableState
// }



const Player: React.FC<PlayerPropsType> = (props: PlayerPropsType) => {

    const doesntKnow = props.points?.voteType === VoteType.NA;
    const showCards = props.tableState === TableState.Opened;

    const [pokeButtonVisible, setPokeButtonVisible] = useState(false);
    const [isHidingPokeButton, setIsHidingPokeButton] = useState(false);


    useEffect(() => {
        if (isHidingPokeButton) {
            setTimeout(() => {
                setPokeButtonVisible(!props.isPoked);
            }, 1000);
        } else {
            setPokeButtonVisible(!props.isPoked);
        }
    }, [props.isPoked, isHidingPokeButton]);

    return (
        <div id={props.userId} className={`player-card ${props.isMe ? 'me' : ''}`}>
            {props.isMe && <div className="triangle"></div>}
            <div className={`flip-card ${props.points != null ? 'chosen' : ''} ${showCards || (props.isMe && props.points) ? 'show' : ''}`}>
                <div className="flip-card-inner">
                    <div className={`flip-card-back playing-card ${showCards ? "front" : "back"}`}></div>
                    {
                        <div className={`playing-card front me flip-card-front`}>
                            <div className="label">{!doesntKnow && props.points?.value}
                                {doesntKnow && <div className={"dont-know-player character dont-know in-player"} title={IdontKnow}></div>}</div>
                        </div>
                    }
                </div>
                {
                    (!props.isMe && !showCards) && <span>
                        <span title="Idle" className={`flip-card-status character idle ${props.playerStatus === PlayerState.Idle ? "in" : "out"}`}></span>
                        <span title="Thinking" className={`flip-card-status character thinking ${props.playerStatus === PlayerState.Thinking ? "in" : "out"}`}></span>
                        <span title="Voted" className={`flip-card-status character voted ${props.playerStatus === PlayerState.Voted ? "in" : "out"}`}></span>
                    </span>
                }
            </div>
            <div className="flip-card-name" title={props.userName}>
                {props.userName}
                {
                    (!props.isMe && props.playerStatus === PlayerState.Idle && pokeButtonVisible)
                    && <button disabled={isHidingPokeButton} className={`flip-card-poke ${isHidingPokeButton ? "clicked" : ""}`} onClick={() => {
                        props.doPoke(props.userId);
                        setIsHidingPokeButton(true);
                        setTimeout(() => {
                            setIsHidingPokeButton(false);
                        }, 1000);
                    }}>
                        <span className="poke-bell"></span>
                    </button>
                }
            </div>
        </div>);
}

const mapStateToProps = (state: RootStore, ownProps: { index: number }) => {
    const player = state.game.players[ownProps.index];

    return {
        isFirst: player.isFirstVoted,
        isLast: player.isLastVoted,
        isMe: player.isMe,
        points: player.vote,
        userId: player.id,
        userName: player.name,
        playerStatus: player.status,
        tableState: state.game.tableState,
        isPoked: player.isPoked
    };
}

const mapDispatch = (dispatch: AppDispatch) => {
    return {
        doPoke: (playerId: string) => {
            dispatch(pokeAction({
                message: playerId
            }));
        }
    }
}
const connector = connect(mapStateToProps, mapDispatch)

export type PlayerPropsType = ConnectedProps<typeof connector>;
export default connector(Player);