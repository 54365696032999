import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import GameContainer from "./components/Game/GameContainer";
import { LoginContol } from "./components/Login/Login";
import { History } from 'history';
import { EnhancedStore } from "@reduxjs/toolkit";

export const reactPointingPokerApp = (store: EnhancedStore, pokerHistory: History<any>) => {
    const app = <Provider store={store}>
        <ConnectedRouter history={pokerHistory}>
            <Switch>
                <Route path="/" exact component={LoginContol} />
                <Route path="/room/:roomId" component={GameContainer} />
                <Route path="/join/:roomId" component={LoginContol} />
            </Switch>
        </ConnectedRouter>
    </Provider>;

    return app;
}