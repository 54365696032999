import React, { useEffect, useRef } from 'react';
import { RootStore } from '../../app/rootReducer';
import withRoomCheckContainer from '../../app/withRoomCheckContainer';
import GameHeader from '../GameHeader/GameHeader';
import Observers from '../Observers/Observers';
import Results from '../Results/Results';
import Main from '../Main';
import ReconnectingIndicator from '../ReconnectingIndicator/ReconnectingIndicator';
import { ConnectionStateType } from '../../enums/ConnectionStateType';
import { connect } from 'react-redux';
import "./GameContainer.scss";
import { AllRightsReserved } from '../Footer/AllRightsReserved';
import { PoweredBy7pace } from '../Footer/PoweredBy7pace';
import GameButtons from '../GameButtons/GameButtons';
import ControlPanel from '../ControlPanel/ControlPanel';
import Settings from '../Settings/Settings';
import GameStat from './GameStat';

export type GamePropsType = {
    isReconnecting: boolean,
    isBeingPoked: boolean
}

export type StringOrNull = string | null;

const Game: React.FC<GamePropsType> = (props: GamePropsType) => {



    return <div className="root-content-wrapper">
        <div className={`game-page-container${props.isReconnecting ? " reconnecting" : ""}`}>
            <GameHeader></GameHeader>
            <Settings></Settings>
            <div className="game-container ">
                <div className="main-container">
                    <div className="left-control-panel">
                        <Observers />
                        <Results />
                    </div>
                    <Main />
                </div>
                <div className="buttons-container">
                    <GameButtons />
                    <ControlPanel />
                </div>
                <div className="game-stats">
                    <GameStat />
                </div>
            </div>
        </div>
        <div className="game-field-footer">
            <AllRightsReserved />
            <PoweredBy7pace />
        </div>
        <ReconnectingIndicator />
    </div>;
}


const mapStateToProps = (state: RootStore): GamePropsType => {
    return {
        isReconnecting: state.connection.state === ConnectionStateType.Disconnected
            || state.connection.state === ConnectionStateType.RetryingToConnect
            || state.connection.isAbandoned,
        isBeingPoked: state.game.isBeingPoked
    };
}

const container = withRoomCheckContainer(connect(mapStateToProps)(Game))

export default container;
