import React from "react"
import { VoteType } from "../../enums/VoteType"
import { IdontKnow } from "../../models/consts"
import { VoteScaleItem } from "../../models/VoteScaleItem"

export type VoteScaleItemViewProps = {
    item: VoteScaleItem;
}

const VoteScaleItemView: React.FC<VoteScaleItemViewProps> = (props) => {

    if (props.item.voteType === VoteType.Normal) {
        return <div className="scale-button">
            {props.item.value}
        </div>;
    }
    if (props.item.voteType === VoteType.NA) {
        return <div className="scale-button">
            {IdontKnow}
        </div>;
    }
}

export default VoteScaleItemView;