import ReactDOM from 'react-dom';
import "./styles.scss";
import "./index.scss";

import rootSaga from './app/rootSaga';

import { reactPointingPokerApp } from './App';
import { pokerHistory, sagaMiddleware, store } from './app/store';

const rootElement = document.getElementById('root');
// then run the saga
sagaMiddleware.run(rootSaga);
ReactDOM.render(reactPointingPokerApp(store, pokerHistory), rootElement);
