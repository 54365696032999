import { ActivityThreshold } from "../app/game/gameSlice";
import { PlayerState } from "../enums/PlayerState";
import { RolesEnum } from "../enums/RolesEnum";
import { VoteScaleItem } from "./VoteScaleItem";

export type User = {
    name: string,
    id: string,
    role: RolesEnum,
    vote?: VoteScaleItem,
    isFirstVoted: boolean,
    isLastVoted: boolean,
    isMe: boolean,
    pointsHistory?: VoteScaleItem[],
    lastActivity: number,
    status: PlayerState,
    isPoked: boolean
}

export function getPlayerStatus(user: User): PlayerState {
    if (user.vote != null) {
        return PlayerState.Voted;
    }
    const shouldIdle = (Date.now() - user.lastActivity) > ActivityThreshold;

    if (user.vote == null && shouldIdle) {
        return PlayerState.Idle;
    }
    return PlayerState.Thinking;
}