import React, { useMemo } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { RootStore } from '../../app/rootReducer';
import { SettingsUpdatePayload, settingsVisibilityAction, updateSettingsAction } from '../../app/settings/settingsSlice';
import { AppDispatch } from '../../app/store';
import { VoteScales } from '../../models/Settings';
import CustomScale from './CustomScale';
import "./settings.scss";
import VoteScaleItemView from './VoteScaleItem';

const CustomScaleId = "custom";

const Settings: React.FC<SettingsPropsType> = (props) => {

    const [selectedVoteScaleId, setSelectedVoteScaleId] = React.useState(props.settings.selectedVoteScaleId);
    const [voteScales, setVoteScales] = React.useState(props.settings.voteScales);

    useMemo(() => {
        setVoteScales(props.settings.voteScales);
        setSelectedVoteScaleId(props.settings.selectedVoteScaleId);
    }, [props.settings]);

    const getSelectedScale = () => {
        return voteScales.find(x => x.id === selectedVoteScaleId);
    }

    const scaleButtons = (scale: VoteScales) => {
        return scale.scale.map((item, index) => {
            return (
                <VoteScaleItemView key={index} item={item}></VoteScaleItemView>
            );
        });
    }

    const scales = useMemo(() => {
        const updateCustomScale = (scale: VoteScales) => {
            setVoteScales([...voteScales.filter(x => x.id !== CustomScaleId), scale]);
        }
        return voteScales.map((scale) => {
            return <div className="scale-set" key={scale.id}>
                <Form.Check
                    type="radio"
                    name="voteScale"
                    id={`default-${scale.id}`}
                    checked={scale.id === selectedVoteScaleId}
                    onChange={() => setSelectedVoteScaleId(scale.id)}
                />
                {scale.id === CustomScaleId ? <CustomScale update={updateCustomScale}
                    isSelected={selectedVoteScaleId === CustomScaleId}
                    scale={scale} /> : scaleButtons(scale)}
            </div>
        })
    }, [voteScales, selectedVoteScaleId]);


    if (!props.isVisible) {
        return null;
    }

    return (<div className="settings-container">
        <Modal show={props.isVisible} dialogClassName="modal-90w" onHide={props.cancel}>
            <Modal.Header closeButton>
                <Modal.Title>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="settings-content">
                    <div className="section">
                        <div className="section-name">Scales</div>
                        {scales}
                    </div>
                    <div className="section">
                        {props.validationErrors["custom"] ? <Alert variant="warning">
                            {props.validationErrors["custom"]}
                        </Alert> : null}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="button cancel" onClick={() => {
                    setSelectedVoteScaleId(props.settings.selectedVoteScaleId);
                    props.cancel();
                }}>
                    Close
                </button>
                <button className="button success" onClick={() => props.saveSettings({
                    activeVoteScale: getSelectedScale(),
                    selectedVoteScaleId: selectedVoteScaleId
                })}>
                    Save
                </button>
            </Modal.Footer>
        </Modal>
    </div>
    );
}

const mapProps = (store: RootStore) => {
    return {
        isVisible: store.settings.isVisible,
        settings: store.settings.settings,
        validationErrors: store.settings.validationErrors
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        cancel: () => dispatch(settingsVisibilityAction(false)),
        saveSettings: (payload: SettingsUpdatePayload) => dispatch(updateSettingsAction(payload))
    };
};

const connector = connect(mapProps, mapDispatchToProps)

export type SettingsPropsType = ConnectedProps<typeof connector>;

export default connector(Settings);