import React from "react";

import { ShareRoomButton } from "./ShareRoomPopup/ShareRoomButton";

import "../../icons.scss";
import "./GameHeader.scss";
import { RootStore } from "../../app/rootReducer";
import { connect, ConnectedProps } from "react-redux";
import { AppDispatch } from "../../app/store";
import { logoutAction } from "../../app/rootSaga";
import { settingsVisibilityAction } from "../../app/settings/settingsSlice";

const GameHeader: React.FC<GameHeaderPropsType> = (props) => {
    return <div className="game-header-container">
        <div className="logo-poker" />
        <div className="game-header-buttons">
            <ShareRoomButton roomLink={window.location.href} />
            <div className="button-container settings" title="Settings" onClick={props.showSettings}><div className="settings-icon" /></div>
            <div className="button-container logout" title="Logout" onClick={props.logout}><div className="logout-icon" /></div>
        </div>
    </div>
}

const mapProps = (store: RootStore) => {
    return {

    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        logout: () => dispatch(logoutAction()),
        showSettings: () => dispatch(settingsVisibilityAction(true))
    };
};

const connector = connect(mapProps, mapDispatchToProps)

export type GameHeaderPropsType = ConnectedProps<typeof connector>;

export default connector(GameHeader);