import { Task } from "@redux-saga/types";
import { createAction } from "@reduxjs/toolkit";
import { routerActions } from "connected-react-router";
import { cancel, fork, put, take } from "redux-saga/effects";
import { loginSlice } from "../components/Login/loginSlice";
import { fullAuthFlowCheck } from "./authSaga";
import { gameSaga } from "./game/gameSaga";
import { settingsSaga } from "./settings/settingsSaga";
import { removeRoomAction } from "./signalR/connectionSlice";
import { pokerSignalRSaga } from "./signalR/pokerSignalRSaga";

export const logoutAction = createAction("logout");
export default function* rootSaga(): Generator {
    while (true) {
        const task = (yield fork(forkAppSagas)) as Task;

        yield take(logoutAction);
        yield put(routerActions.push("/"));

        yield put(loginSlice.actions.setDesiredRoomId(""));
        yield cancel(task);
    }
}

//function to fork all tasks and return the task object
function* forkAppSagas(): Generator {
    yield fork(fullAuthFlowCheck);
    yield fork(pokerSignalRSaga);
    yield fork(gameSaga);

    yield fork(settingsSaga);
    yield take(removeRoomAction);
    //call remove room action
    yield put(loginSlice.actions.setRoomRemoved(true));
    yield put(logoutAction());
}