import React from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../app/rootReducer';
import { TableState } from '../../enums/TableState';
import { User } from '../../models/User';
import Player from './Player';
import "./Player.scss";

export type PlayersRowPropsType = {
    players: User[],
    showCards: boolean
}

const PlayersRow: React.FC<PlayersRowPropsType> = (props) => {

    const findEllipseCoordinates = (phi: number): { x: number, y: number } => {
        let a = 300;
        let b = 200;

        let x = a * Math.cos(phi);
        let y = b * Math.sin(phi);

        return { x: Math.round(x), y: Math.round(y) };
    }

    const findPhis = (numberOfPlayers: number): number[] => {
        let divider = numberOfPlayers;


        let angle = 2 * Math.PI / divider;

        var arr = [];
        for (var i = 0; i < numberOfPlayers; i++) {
            let computedAngle = i * angle;
            arr.push(computedAngle);
        }

        return arr;
    }

    const renderCards = () => {
        let phis = findPhis(props.players.length);
        let coords = phis.map(phi => findEllipseCoordinates(phi));

        return props.players.map((player, index) => (
            <div key={player.id} className="player-wrapper" style={{ left: coords[index].x + 'px', top: coords[index].y + 'px' }}>
                {<Player index={index} />}
            </div>
        ));
    }
    return (<div className="cards-wrap">{renderCards()}</div>);
}

const mapStateToProps = (state: RootStore): PlayersRowPropsType => {
    return {
        players: state.game.players,
        showCards: state.game.tableState === TableState.Opened
    };
}
export default connect(mapStateToProps)(PlayersRow);