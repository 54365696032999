import { VoteScaleItem } from "./VoteScaleItem";


export type VotesState = {
    votes: SingleVoteStat[],
    highlightedVote?: VoteHighlight,
};

export type SingleVoteStat = {
    vote: VoteScaleItem,
    votesCount: number,
    percentage: number
}

export type VoteHighlight = {
    highlightType: HighlightType,
    highlightedVote?: SingleVoteStat,
    highlightedVoteSecond?: SingleVoteStat
}

export enum HighlightType {

    SingleVote = "SingleVote",
    Consensus = "Consensus",
    MostVotes = "MostVotes",
    Doubts = "Doubts",
    Nothing = "Nothing"
}