import { CombinedState, combineReducers, Reducer } from "@reduxjs/toolkit";
import { connectRouter, RouterState } from "connected-react-router";
import { loginInitialState, loginSlice } from "../components/Login/loginSlice";
import { gameInitialState, gameSlice } from "./game/gameSlice";
import { connectionInitialState, connectionSlice } from "./signalR/connectionSlice";
import { LocationState, History } from 'history';
import { settingsInitialState, settingsSlice } from "./settings/settingsSlice";
export const initialState = {
    login: loginInitialState,
    connection: connectionInitialState,
    game: gameInitialState,
    settings: settingsInitialState,
    router: null as RouterState<LocationState>,
};
/*
 * This is the root state of the app
 * It contains every substate of the app
 */
export type RootStore = typeof initialState;
export const createRootReducer = (history: History<any>): Reducer<CombinedState<RootStore>> => combineReducers<RootStore>({
    login: loginSlice.reducer,
    connection: connectionSlice.reducer,
    game: gameSlice.reducer,
    router: connectRouter(history),
    settings: settingsSlice.reducer,
})
