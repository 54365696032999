import React, { Component } from 'react';
import { Card, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RootStore } from '../../app/rootReducer';
import { VoteType } from '../../enums/VoteType';
import { VotesState } from '../../models/VotesState';
import "./results.scss";


export type ResultsPropsType = {
    votesState: VotesState
}


const Results: React.FC<ResultsPropsType> = (props) => {

    //memo with rows 
    const memoRows = React.useMemo(() => {

        // votesState null check    
        if (!props.votesState) {
            return null;
        }

        const rows = props.votesState.votes.map((vote, index) => {
            return (
                <tr key={index} className={index % 2 !== 1 ? "striped" : ""}>
                    <td>
                        {vote.vote.voteType !== VoteType.NA ? vote.vote.value : <div className="character dont-know in-table" title="I don't know"></div>}
                    </td>
                    <td>{vote.votesCount}</td>
                    <td>{vote.percentage}%</td>
                </tr>
            )
        })
        return rows;
    }, [props.votesState]);

    if (!props.votesState) {
        return null;
    }

    return <div className="results-section">
        <Card>
            <Table striped hover className="results-table">
                <thead>
                    <tr>
                        <th>
                            POINTS
                        </th>
                        <th>
                            VOTES
                        </th>
                        <th>
                            PERCENT
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {memoRows}
                </tbody>
            </Table>
        </Card>
    </div>;
}

const mapStateToProps = (store: RootStore): ResultsPropsType => {
    return {
        votesState: store.game.votesState
    };
}

export default connect(mapStateToProps)(Results);