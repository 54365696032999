import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RolesEnum } from "../../enums/RolesEnum"


export const loginInitialState = {
    name: "",
    role: RolesEnum.Player,
    desiredRoomId: "",
    isRoomRemoved: false
};
export type LoginState = typeof loginInitialState;
export type LoginStateSimple = { name: string, role: RolesEnum };

export const doLoginAction = createAction<LoginStateSimple>("doLoginAction");

export const loginSlice = createSlice({
    name: 'login',
    initialState: loginInitialState,
    reducers: {
        setLoginInfo: (state: LoginState, action: PayloadAction<LoginStateSimple>) => {
            state.name = action.payload.name.trim();
            state.role = action.payload.role;
        },
        setDesiredRoomId: (state: LoginState, action: PayloadAction<string>) => {
            state.desiredRoomId = action.payload;
        },
        setRoomRemoved: (state: LoginState, action: PayloadAction<boolean>) => {
            state.isRoomRemoved = action.payload;
        }
    }
})

export default loginSlice.reducer;