import { put, takeEvery } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { connectionSlice } from "../signalR/connectionSlice";
import { SignalActions } from "../signalR/SignalRMiddleware";
import { SettingsUpdatePayload, updateSettingsAction } from "./settingsSlice";

export function* settingsSaga() {
    yield takeEvery(updateSettingsAction, updateSettings);
}

function* updateSettings(updateSettingsAction: PayloadAction<SettingsUpdatePayload>) {
    yield put(connectionSlice.actions.callSignalR({
        action: SignalActions.UpdateSettings,
        args: [updateSettingsAction.payload]
    }));
}
