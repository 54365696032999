const pokeSound = require("../../sounds/poke-sound.mp3");

export const launchIsBeingPokedTitle = () => {
    const originalTitle = document.title;
    let index = 0;
    const interval = setInterval(() => {
        document.title = index % 2 === 0 ? "Your estimation is missing!" : originalTitle;
        index++;
    }, 1000);

    new Audio(pokeSound.default).play();
    return {
        interval: interval,
        originalTitle: originalTitle
    }
}

export const stopIsBeingPokedTitle = (interval: NodeJS.Timeout, originalTitle: string) => {
    document.title = originalTitle;
    clearInterval(interval);
}