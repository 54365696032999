import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../app/rootReducer';
import { RolesEnum } from '../../enums/RolesEnum';
import { User } from '../../models/User';

import './Observers.scss';

type ObsereversPropsType = {
    observers: User[],
    currentUserId: string
}

const Observers: React.FC<ObsereversPropsType> = (props) => {
    const obs = useMemo(() => {
        return props.observers.map(observer => {
            return <span className="observer-user" key={observer.id}>{observer.name}</span>
        })
    }, [props.observers]);

    return <div className="observers">
        {props.observers.length > 0 ? <span className="observers-title">OBSERVERS: </span> : <></>}
        {obs}
    </div>

}

const mapState = (state: RootStore): ObsereversPropsType => {
    return {
        currentUserId: state.connection.connectionId,
        observers: state.game.observers
    };
}
const connected = connect(mapState)(Observers);
export default connected;